<template>
    <div>
      <b-row>
        <b-col lg="6" md="6" sm="6" class="heading-title">
          <h4>View Booking</h4>
          <b-breadcrumb class="p-0 m-0" id="breadcrumb-heading">
            <b-breadcrumb-item to="/dashboard" custom> Home </b-breadcrumb-item>
            <b-breadcrumb-item active>View Booking</b-breadcrumb-item>
          </b-breadcrumb>
        </b-col>
        <b-col lg="6" md="6" sm="6" class="btns-header"> </b-col>
      </b-row>
      <br />
      <b-card class="mb-4 w-100">
      <div class="venue-heading">
          <b-row>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
              <multiselect
                  :options="vendor_ids"
                  placeholder="Select Vendor"
                  v-model="vendor_id_value"
                  track-by="name"
                          label="name"
                >
                </multiselect>
              </div>
            </b-col>
           <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
                 <b-form-datepicker
                  id="example-datepicker"
                  v-model="start_date"
                  placeholder="Start date"
                  class="mb-2"
                  :max="end_date"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="6"
              sm="12"
            >
              <div class="w-100">
               <b-form-datepicker
                  id="example-datepicker2"
                  placeholder="End date"
                  :min="start_date"
                  v-model="end_date"
                  class="mb-2"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                  }"
                ></b-form-datepicker>
              </div>
            </b-col> 
           
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="12"
              sm="12"
            >
              <div class="w-100 d-flex justify-content-end button-text">
                <div class="p-1">
                  <b-button variant="primary" @click="applyFilter()">
                    Apply</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button variant="primary" @click="resetFilter()">
                    Reset</b-button
                  >
                </div>
                <div class="p-1">
                  <b-button
                    id="button_width"
                    @click="export_pdf()"
                    variant="primary"
                  >
                    <i class="mdi mdi-export"></i>Export</b-button
                  >
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
  
        <div>
          <b-row>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="3"
              md="3"
              sm="3"
            >
              <div
                class="
                  w-100
                  justify-content-sm-center
                  justify-content-xl-start
                  justify-content-md-start
                  justify-content-lg-end
                "
              >
              <div class="mt-2 venue-flex">
                  <b-form-select
                    id="select_perpage"
                    v-model="pageSize"
                    :options="options"
                    @change="handlePageSizeChange($event)"
                    ></b-form-select>
                 
              </div>
              </div>
            </b-col>
            <b-col
              class="d-flex align-items-stretch"
              cols="12"
              lg="9"
              md="12"
              sm="12"
            >
              <div class="w-100 p-2">
                <b-form-input
                  v-model="search"
                  id="search"
                  type="text"
                  placeholder="Search here"
                  v-on:keyup="key_search()"
                ></b-form-input>
              </div>
            </b-col>
          </b-row>
        </div>
        <br />
        <div>
          <b-table
            :busy="load"
            :fields="fields"
            :items="bookings"
            responsive
            hover
            bordered
            show-empty
            empty-text="No Data Available"
            class="text-center"
            :tbody-transition-props="transProps"
            id="table-transition-example"
            :primary-key="vendor_auto_id"
          >
          <template  #cell(total_booking_price)="data">
               <span v-html="currency_check(data.item.total_booking_price)"></span>
            </template>
          
            <template  #cell(booking_type)="data">
            
            <span  class="badge badge-success" v-if="data.item.booking_type=='Booked'" >
             {{ data.item.booking_type }}
             </span>
            <span class="badge badge-danger" v-else>
              {{ data.item.booking_type }}
             </span>
        </template>
        <template  #cell(book_ref_no)="data">
							<a href="javascript:void(0)"  @click="$router.push('/Booking/manage_booking_view/'+data.item.book_ref_no)">{{ data.item.book_ref_no }}</a>
						</template>
            <template  #cell(status)="data">
           <span v-if="data.item.booking_type=='Booked'">
            <span  v-if="data.item.allData.status==2" >
              <span class="badge badge-warning">Cancelled</span>
            </span>

            
            <span  v-else-if="data.item.status==1 && data.item.allData.invoice_number==null" >
              <span class="badge badge-info">Event</span>
            </span>
            <span  v-else-if="data.item.status==2  && data.item.allData.invoice_number==null" >
              <span class="badge badge-primary">Closing</span>
            </span>
            <span  v-else-if="data.item.allData.invoice_number!=null">
              <span class="badge badge-success">Closed</span>
            </span>
            
            <span class="badge badge-danger" v-else>
              Pending
             </span>
            </span>
        </template>
        <template #cell(booking_id)="data">
         <!--    <a href="javascript:void(0)" @click="newPrint(data.item.booking_id)">
              <i class="fas fa-print fa-lg text-dark"></i>
            </a> -->
         <div v-if="data.item.allData.status==2" class="text-danger">
            Cancelled
        </div>
        <div v-else>
         
            <router-link :to="'/Booking/details/' + data.item.booking_id" >
              <i class="fas fa-eye fa-lg text-dark" @click="Load_booking_details(data.item.allData)"></i>
            </router-link>

            <router-link :to="'/Booking/invoice/' + data.item.booking_id " v-if="data.item.allData.invoice_number==null">
              <i class="fas fa-file fa-lg text-dark ml-2"></i>
            </router-link>

            <router-link :to="'/Booking/final_invoice/' + data.item.booking_id " v-else>
              <i class="fas fa-file fa-lg text-success ml-2"></i>
            </router-link>

          </div>
          
          </template>
           

          </b-table>
          <b-row align-v="center">
            <b-col cols="4">
              <span
                >Showing <b>{{ totalRows }}</b> out of
                <b>{{ totalData }}</b> entries.</span
              >
            </b-col>
            <b-col cols="8">
              <b-pagination
                v-model="page"
                :total-rows="count"
                :per-page="pageSize"
                @change="handlePageChange"
                prev-text="Prev"
                next-text="Next"
                align="right"
              ></b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  <script>
  import axios from "axios";
  import Multiselect from "vue-multiselect";
  import { mapState } from 'vuex';
  import helpers from "../../mixins/helpers";
  export default {
    name: "mybooking",
    mixins: [helpers],
    data: () => ({
      title: "mybooking",
      transProps: {
          // Transition name
          name: 'flip-list'
        },
     
      fields: [
      //  'index',
      {
        key: "book_ref_no",
        label: "Booking Ref. no.",
        sortable: true,
      }, 
      {
          key: "vendor_auto_id",
          label: "Vendor id",
          sortable: false,
        },
        {
          key: "vendor_name",
          label: "Vendor Name",
          sortable: false,
        },
     
      {
        key: "place_name",
        label: "Venue Name",
        sortable: true,
      },
      {
        key: "booked_on",
        label: "Event date",
        sortable: true,
      },
      {
        key: "booking_type",
        label: "Booking Type",
      },
    
      {
        key: "total_booking_price",
        label: "Amount",
        sortable: true,
        tdClass: 'text-right',
      },
      {
        key: "status",
        label: "Status",
      },
    
      {
        key: "booking_id",
        label: "Action",
      },
    ],
      bookings: [],
      options: [10, 20, 30, 50],
  
      pageSize: 10,
      show: false,
      load: true,
      filter: "",
      search: "",
      CurrentPage: 1,
      sortBy: "created_at",
  
      page: 1,
      count: 0,
      totalData: "",
      totalRows: "",
  
      PerPage: "50",
      vendor_ids: [],
      start_date: "",
      end_date: "",
      booked_on: "",
      vendor_id_value: "",
      curncy: "",
    }),
    components: { Multiselect },
    methods: {
      key_search() {
        const value = "1";
        this.handlePageChange(value);
      },
      handlePageChange(value) {
        this.page = value;
        this.load = true;
        this.bookingListing();
      },
      applyFilter() {
        const value = "1";
        this.handlePageChange(value);
      },
      getRequestParams(page, pageSize, sortBy = "created_at") {
        let params = {};
        if (page) {
          params["page"] = page - 1;
        }
        if (pageSize) {
          params["size"] = pageSize;
        }
        params["sortBy"] = sortBy;
        return params;
      },
      bookingListing() {
        this.$store.commit("SET_BOOKING_HEADER", 'Manage Booking');
        this.load = true;
        // this.venue = [];
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        this.$store.commit("SET_ADMIN_ID", this.vendor_id_value);
        axios
          .post("/admin/get_bookings", {
            params,
            search_keyword: keyword,
            from_date: this.start_date,
            to_date: this.end_date,
            booked_on: this.booked_on,
            vendor_id: this.vendor_id_value ?  this.vendor_id_value.value : '',
          })
          .then((resp) => {
            if (resp.data.status_code == 200) {
              this.bookings = [];
              if (resp.data.status) {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.bookings.length;
  
                for (var i = 0; i < resp.data.bookings.length; i++) {
                  var shift;
                if(resp.data.bookings[i].booked_shift_type==1)
                {
                  shift="Morning";
                }
                else if(resp.data.bookings[i].booked_shift_type==2)
                {
                  shift="Afternoon";
                }
                else if(resp.data.bookings[i].booked_shift_type==3)
                {
                  shift="Evening";
                }

                var event_date =  resp.data.bookings[i].from_dates;
                var stats ;
if(this.today_date()==event_date)
{
   stats = "1";
}
else if(this.today_date() > event_date)
{
  stats = "2";
}

else
{
  stats = "3";
}

                  this.bookings.push({
                    allData: resp.data.bookings[i],
                    book_ref_no: resp.data.bookings[i].booking_auto_id,
                    from_date: this.date_format(resp.data.bookings[i].from_dates),
                    to_date: resp.data.bookings[i].to_date,
                    billing_phone: resp.data.bookings[i].billing_phone,
                    billing_email: resp.data.bookings[i].billing_email,
                    total_booking_price:
                    this.numberWithCommas(Math.round(resp.data.bookings[i].booking_amount)),
                    booked_on: this.date_format(resp.data.bookings[i].from_dates) +' - '+shift,
                    booking_type: resp.data.bookings[i].booking_type =="full" ? "Booked" :"Blocked",
                    place_name: resp.data.bookings[i].child_venue_name,
                    vendor_id: resp.data.bookings[i].vendor_id,
                    vendor_name: resp.data.bookings[i].vendor_name,
                    booking_id: resp.data.bookings[i].booking_id,
                    vendor_auto_id: resp.data.bookings[i].vendor_auto_id,
                    status: stats,
                  });
                }
                this.show = false;
                this.load = false;
              } else {
                this.count = this.totalData = resp.data.total_records;
                this.totalRows = resp.data.bookings.length;
  
                this.show = false;
                this.load = false;
              }
            }
          });
      },
      handlePageSizeChange(event) {
        this.pageSize = event;
        this.load = true;
        this.page = 1;
        this.bookingListing();
      },
      AddOffline() {
        this.$router.push("/add-booking");
      },
      today_date()
			{
				var myDate = new Date();
				var d = new Date(myDate),
				month = '' + (d.getMonth() + 1),
				day = '' + d.getDate(),
				year = d.getFullYear();
				
				if (month.length < 2) 
				month = '0' + month;
				if (day.length < 2) 
				day = '0' + day;
				return [year,month,day].join('-');
			},
      get_venor_id() {
        /* axios.post("/api/get_vendor_ids").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              this.vendor_ids = resp.data.vendor_ids;
            }
          }
        }); */
  
        axios.post("/admin/get_all_vendors_list").then((resp) => {
          if (resp.data.status_code == 200) {
            if (resp.data.status) {
              for (var i = 0; i < resp.data.vendors.length; i++) {
                this.vendor_ids.push({
                  value: resp.data.vendors[i].user_id,
                  name:
                    resp.data.vendors[i].first_name +
                    " " +
                    resp.data.vendors[i].last_name,
                });
              }
            }
          }
        });
  
      },
      resetFilter() {
        this.start_date = "";
        this.end_date = "";
        this.search = "";
        this.booked_on = "";
        (this.vendor_id_value = ""), this.bookingListing();
      },
      export_pdf() {
        var keyword = this.search;
        const params = this.getRequestParams(
          this.page,
          this.pageSize,
          this.sortBy
        );
        axios
          .post(
            "/admin/bookings_pdf_export",
            {
              params,
              search_keyword: keyword,
              from_date: this.start_date,
              to_date: this.end_date,
              booked_on: this.booked_on,
              vendor_id: this.vendor_id_value,
            },
            {
              responseType: "blob",
            }
          )
          .then((resp) => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "booking_list.pdf");
            document.body.appendChild(link);
            link.click();
          });
      },
      currency_check(amt) {
         
          
         return '<i class="mdi '+this.curncy+'"></i>'+amt;
     },
     getProfile() {
       axios.get("/admin/get_profile").then((resp) => {
             if (resp.data.status_code == 200) {
                 this.curncy= resp.data.user_details.currencys;
             }
         });
     },
    },
    mounted() {
      this.vendor_id_value=this.set_admin_id;
      this.bookingListing();
      this.get_venor_id();
      this.getProfile();
    },
    computed: {
      ...mapState(["set_admin_id"]),
  }
  };
  </script>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  <style scoped>
  
  </style>